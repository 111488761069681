@font-face {
    font-family: "Montserrat";
    src: url("../../Fonts/Montserrat-SemiBold.ttf");
}

.customComponents__buttonSmall {
    width: 100px;
    height: 40px;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #d1d1d1;
    background-color: #39d84b;
    color: white;
    font-size: 13px;
    font-family: Montserrat;
    cursor: pointer;
    @media screen and (max-width: 400px) {
        width: 70px;
        
    }
}
body.dark {
    .customComponents__buttonSmall {
        border: 1px solid #58596e;
        background-color: #2b2c3e;
        color: white;
    }
}
