.route__login{
    width: 100%;
    height: 100%;
    background-color: #2B2B39;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    &__right{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        p{
            color: white;
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 25px;
        }
        h1{
            color: white;
            margin-bottom: 20px;
        }
        label{
            color: white;
            
        }
        input{
            color: white;
            background-color: transparent;
            width: 250px;
            height: 40px;
            border-radius: 10px;
            margin-bottom: 10px;
            border: 1px solid gray;
            box-sizing: border-box;
            padding: 5px 10px;

            ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: white;
                opacity: 1; /* Firefox */
              }
              
              :-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: white;
              }
              
              ::-ms-input-placeholder { /* Microsoft Edge */
                color: white;
              }

        }
        button{
            width: 250px;
            margin-top: 10px;
            height: 40px;
            border-radius: 10px;
            color: white;
            border: none;
            background-color: #5956E9;
            cursor: pointer;
        }
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: white;
            opacity: 1; /* Firefox */
          }
          
          :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: white;
          }
          
          ::-ms-input-placeholder { /* Microsoft Edge */
            color: white;
          }

    }
}