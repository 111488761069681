.route__poslano {
    position: relative;
    width: 100%;
    height: auto;
    padding: 10px;

    & .customComponents__table {
        .customComponents__table__data {
            position: relative;

            & .customComponents__spinner {
                position: relative;
                width: 32px;
                height: 32px;
                background-color: transparent;

                & img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}