.route__izlistaj {
    width: 100%;
    height: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: relative;

    & > textarea {
        width: 200px;
        height: 200px;
        resize: none;
        margin: 10px 0px;
    }
}