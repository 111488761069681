.route__kreiraj {
    position: relative;
    padding: 10px;
    min-width: 2400px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    &--normal {
        min-width: unset;
        width: 100%;
    }
    & .customComponents__spinner {
        position: fixed;
        top: 50px;
        left: 0;
        width: 100%;
        height: calc(100% - 50px);
        transition: opacity 0.3s ease;
    }
    &__addBtn {
        padding: 10px;
        color: white;
        background-color: #215c97;
        border: none;
        outline: none;
        cursor: pointer;
    }
    &__cInput {
        border: none;
        background-color: transparent;
        text-align: center;
    }
    &__town {
        position: relative;
        width: 100%;

        & .customComponents__spinner {
            position: relative;
            background-color: transparent;
            width: 16px;
            height: 16px;

            & img {
                width: 100%;
                height: 100%;
            }
        }
    }
    &__infoP {
        color: red;
    }
    & .customComponents__table {
        margin-bottom: 20px;

        &__data, &__head {
            column-gap: 10px;
        }
        &__head {
            position: sticky;
            top: 0;
        }
    }
}

.route__kreiraj__modal {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}
.route__kreiraj__modalEdit {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    column-gap: 10px;

    & .customComponents__spinner {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        width: auto;
        background-color: transparent;
    }
    &__wrap {
        width: 100%;

        & .component__customComponent__dropbox {
            margin-bottom: 10px;

            & .component__customComponent__dropbox__text {
                color: #00a3ff;
                font-weight: bold;
            }
        }
        & .customComponents__input  {
            width: 100%;

            & input {
                width: 100%;
            }
        }
    }
}