.component__header {
    width: 100%;
    height: 100%;
    background-color: #2B2B39;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 100px;
    position: relative;

    &--stateful {
        justify-content: flex-start;

        & > p {
            margin-right: 20px;
        }
    }
    & .customComponents__spinner {
        position: relative;
        width: 24px;
        height: 24px;
        margin-right: 20px;
        background-color: transparent;

        & img {
            width: 100%;
            height: 100%;
        }
    }
    &__progress {
        width: 300px;
        height: 16px !important;
        border-radius: 10px;
        border: 1px solid gray;
        overflow: hidden;
        position: relative;

        &__inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: #215c97;
            transition: width 0.3s ease;
        }
    }
    div{
        display: flex;
        height: 100%;
    }
    h4{
        color: white;
        cursor: pointer;
       
    }
    &__tabs{
        width: 150px;
        padding: 10px;
        transition: all 0.3s ease;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
    }
    &__tabsActive{
        background-color: rgb(33, 92, 151);
    }
}