.customComponents__dropBox {
    width: auto;
    padding: 10px;
    position: relative;
    min-width: 200px;
    margin-right: 10px;
    border: 1px solid #00A3FF;
    border-radius: 5px;

    &__selected {
        width: 100%;
        display: inline-block;
        color: #00a3ff;
        font-family: Montserrat;
        font-size: 14px;
        cursor: pointer;
        position: relative;
        & > img {
            width: 10px;
            height: 10px;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%) rotate(180deg);
        }
        & > * {
            pointer-events: none;
        }
        &__icon {
            position: relative !important;
            top: 50% !important;
            left: 0 !important;
            height: 100% !important;
            width: auto !important;
            right: unset !important;
            transform: none !important;
        }
    }
    &__all {
        height: 0;
        overflow: hidden;
        position: absolute;
        top: 130%;
        transition: height 0.3s ease;
        background-color: #EFEFF6;
        z-index: 50;
        width: 100%;
        left: 0;
        box-shadow: -1px 15px 27px 0px rgba(0,0,0,0.75);
        border-radius: 10px;
        &__input {
            width: 90%;
            margin: 10px 5%;
            border: 1px solid grey;
            outline: none;
            padding: 5px 10px;
            color: #00a3ff;
            border-radius: 10px;
        }
        &__single {
            width: 90%;
            margin: 10px auto;
            color: #5e606f;
            font-family: Montserrat;
            font-size: 14px;
            cursor: pointer;
        }
    }
}


body.dark{
    .customComponents__dropBox {
        &__selected {
            color: white;
        }
        &__all {
            background-color: #2B2D3E;
            &__single {
                color: white;
            }
            &__input {
                color: white;
                background-color: #2B2D3E;
            }
        }
    }
}