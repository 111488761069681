@font-face {
    font-family: "Montserrat";
    src: url("../../Fonts/Montserrat-SemiBold.ttf");
}

.customComponents__input {
    .floating-label-group {
        position: relative;
        margin-top: 15px;
        margin-bottom: 25px;
        margin-right: 0 !important;

        .floating-label{
            color: #00a3ff;
            position: absolute;
            pointer-events: none;
            width: 100%;
            top: 9px;
            left: 12px;
            font-size: 14px;
            font-family: Montserrat;
            transition: all 0.3s ease;
        }

        input:focus ~ .floating-label,
        input:not(:focus):not(:placeholder-shown) ~ .floating-label{
            top: -15px;
            bottom: 0px;
            left: 10px;
            opacity: 1;
            color: #868484;
        }
        
    }

    .row {
        margin-top: 50px;
    }
    .form-control {
        border: none;
        border-bottom: 1px solid #c4c4c4;
        width: 220px;
        box-sizing: border-box;
        padding: 10px;
        font-family: Montserrat;
        font-size: 14px;
        color: #00a3ff;
        background-color: transparent;
    }
    input {
        transition: border-color 0.3s ease;
    }
    input:focus {
        border: none;
        outline: none;
        border-bottom: 1px solid #00a3ff;
    }
}
body.dark {
    .floating-label {
        color: white;
    }
    input {
        color: white;
    }
}
