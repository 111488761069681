@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

.customComponents__spinner{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #EDEEF6;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    z-index: 5000;
    img{
        margin: auto;
        animation: spin 2s linear infinite;
    }
}
body.dark{
    .customComponents__spinner{
        background-color: #232232;
    }
    background-color: #232232;

}